<template>
  <v-container>
    <liff-member-block :members="members" :isMainManager="isMainManager" :control="isMainManager" :autoInvite="autoInvite"
      :shareConfig="shareConfig" :providerId="providerId" :otpApi="otpApi" @setOtpCode="setOtpCode"
      @setInviteRole="setInviteRole" @refresh="getMembers" />
  </v-container>
</template>


<script lang="babel" type="text/babel">
export default {
  components: {
    liffMemberBlock: () => import('@/modules/member/components/liffMemberBlock.vue')
  },
  data: () => ({
    members: [],
    otpCode: '',
    inviteRole: '',
  }),
  mounted() {
    this.getMembers()
  },
  computed: {
    isMainManager() {
      return this.$store.getters['member/isProviderMainManager']
    },
    liffDomain() {
      return this.$store.getters[`base/liffDomain`];
    },
    // 是否自動邀請
    autoInvite() {
      return !!this.$route.query.autoInvite
    },
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    providerName() {
      return this.$store.getters[`member/providerName`]
    },
    memberId() {
      return this.$store.getters[`token/memberId`]
    },
    linerpAccountId() {
      return this.$store.getters[`base/eagleEnv`].linerpAccountId
    },
    otpApi() {
      const params = this.inviteRole ? { roles: [ this.inviteRole ] } : {}
      return () => this.$api.collection.providerApi.inviteConfig(this.providerId, params)
    },
    inviteUrl() {
      return `${this.liffDomain}/provider/join/${this.providerId}?code=${this.otpCode}`
    },
    shareConfig() {
      if(!this.inviteUrl) return null
      const roleName = this.inviteRole ? this.inviteRole : 'co_manager'
      return [
        {
          type: 'template',
          altText: '請點此加入供應商',
          template: {
            type: 'buttons',
            title: `我要邀請你加入${ this.providerName }的成員`,
            text: '請依照以下按鈕操作',
            actions: [
              {
                type: 'uri',
                label: '1. 加入Linerp官方帳號',
                uri: `https://line.me/R/ti/p/${this.linerpAccountId}`
              },
              {
                type: 'uri',
                label: `2. 成為${ this.$t(roleName) }`,
                uri: this.inviteUrl,
              }
            ],
          },
        },
      ]
    },
  },
  methods: {
    setInviteRole(role) {
      this.$root.gaLogEvent('供應商_點擊_邀請共同管理員')
      this.inviteRole = role
    },
    setOtpCode(code) {
      this.otpCode = code
    },
    async getMembers() {
      this.$store.dispatch('loading/active')
      try {
        let members = await this.$api.collection.providerMemberApi.index(this.providerId, {pager: 0})
        this.members = members.map(member => {
          return {
            ...member,
            isMaster: member.IsProviderMaster
          }
        })
      } catch(err) {
        // this.$snotify.error('讀取會員失敗')
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    async copyInviteUrl() {
      try {
        await this.$copyText(this.inviteUrl)
      } catch (error) {
        console.warn(error)
      } finally {
        this.$snotify.success(this.inviteUrl, this.$t('copy.successfully'))
      }
    },
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    afterSaveSuccessCallback(result) {

    },
    async readApi(target) {
      const res =  await this.$api.collection.providerApi.read(this.providerId)
      this.$store.dispatch('base/setLayoutProvider', res)
      return res
    },
    async updateApi(target, formData) {
      const res = await this.$api.collection.providerApi.update(this.providerId, formData)
      this.$store.dispatch('base/setLayoutProvider', res)
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
